window.addEventListener('resize', function (e) {
  console.log(window.innerWidth);
});

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('[data-btnburger]')) {
    document.querySelectorAll('[data-btnburger]').forEach((el) => {
      el.addEventListener('click', clickBurger);
    });
  }

  if (document.querySelector('.main-menu-overlay')) {
    document
      .querySelector('.main-menu-overlay')
      .addEventListener('click', closeMainMenu);
  }

  if (document.querySelector('.main-menu')) {
    document
      .querySelector('.main-menu')
      .addEventListener('click', closeMainMenu);
  }

  if (document.querySelector('input[type=tel]')) {
    telMasksTrigger();
  }

  if (document.querySelector('.calc')) {
    const calc = new Calc(document.querySelector('.calc'));

    window.calc = calc;
  }

  if (document.querySelector('[data-texture]')) {
    document
      .querySelector('[data-texture]')
      .addEventListener('change', selectTabs);

    selectTabs();
  }

  const sliderPromotions = new Swiper('.slider-promotions', {
    spaceBetween: 20,

    pagination: {
      el: '.swiper-pagination',
    },

    breakpoints: {
      320: {
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 2,
      },

      1200: {
        slidesPerView: 3,
      },
    },
  });

  const sliderTriple = new Swiper('.slider-triple', {
    spaceBetween: 20,
    watchSlidesProgress: true,

    pagination: {
      el: '.swiper-pagination',
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      320: {
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 2,
      },

      1200: {
        slidesPerView: 3,
      },
    },
  });

  const sliderFormTabs = new Swiper('.slider-form-tabs', {
    watchSlidesProgress: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 4,
      },

      400: {
        slidesPerView: 3,
        spaceBetween: 4,
      },

      768: {
        slidesPerView: 4,
        spaceBetween: 8,
      },

      1200: {
        slidesPerView: 7,
        spaceBetween: 8,
      },
    },
  });

  const sliderСertificates = new Swiper('.slider-certificates', {
    watchSlidesProgress: true,
    spaceBetween: 24,

    pagination: {
      el: '.swiper-pagination',
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      320: {
        slidesPerView: 1,
      },

      400: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },

      1200: {
        slidesPerView: 4,
      },
    },
  });

  imgLightbox('img-lightbox-link');

  imgLightbox('img-lightbox-dinamic');

  createScrollbarWidth();

  if (document.querySelector('#form-tabs')) {
    startFormTabs();
  }

  if (document.querySelector('.form-tabs-content')) {
    tabsImgsChanger();
  }

  if (
    document.querySelector('[data-gift]') &&
    document.querySelector('.present-lenta')
  ) {
    document.querySelectorAll('[data-gift]').forEach((el) => {
      el.addEventListener('mouseenter', showGift);
      el.addEventListener('mouseleave', hideGift);
    });
  }
});

function clickBurger() {
  toggleMainMenu();
}

function openMainMenu() {
  doFreezePage();
  document.querySelector('.main-menu').classList.add('active');
  document.querySelector('.main-menu-overlay').classList.add('active');
  document.querySelectorAll('[data-btnburger]').forEach((el) => {
    el.classList.add('active');
  });
}
function closeMainMenu() {
  document.querySelector('.main-menu').classList.remove('active');
  document.querySelector('.main-menu-overlay').classList.remove('active');
  document.querySelectorAll('[data-btnburger]').forEach((el) => {
    el.classList.remove('active');
  });
  undoFreezePage();
}
function toggleMainMenu() {
  toggleFreezePage();
  document.querySelector('.main-menu').classList.toggle('active');
  document.querySelector('.main-menu-overlay').classList.toggle('active');
  document.querySelectorAll('[data-btnburger]').forEach((el) => {
    el.classList.toggle('active');
  });
}

function telMasksTrigger() {
  document.querySelectorAll('input[type=tel]').forEach((el) => {
    window.telArr = [];

    window.telArr.push(
      IMask(el, {
        mask: '+{7} 000 000-00-00',
      })
    );
  });
}

function toggleFreezePage() {
  const html = document.querySelector('html');

  createScrollbarWidth();
  html.classList.toggle('freeze');
}
function doFreezePage() {
  const html = document.querySelector('html');

  createScrollbarWidth();
  html.classList.add('freeze');
}
function undoFreezePage() {
  const html = document.querySelector('html');

  createScrollbarWidth();
  html.classList.remove('freeze');
}

function createScrollbarWidth() {
  const html = document.querySelector('html');
  const scrollDiv = document.createElement('div');
  scrollDiv.style.cssText =
    'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
  document.body.appendChild(scrollDiv);
  const scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  html.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`);
}

function selectTabs() {
  const selectTexture = document.querySelector('[data-texture]');
  const selectProducer = document.querySelector('[data-producer]');

  if (
    selectTexture.value == 'Матовая' ||
    selectTexture.value == 'Сатиновая' ||
    selectTexture.value == 'Глянцевая'
  ) {
    selectProducer.querySelectorAll('option').forEach((el) => {
      el.classList.remove('d-none');
    });
    selectProducer.querySelector('[data-type=descor').classList.add('d-none');

    if (selectProducer.value == 'Descor') {
      selectProducer.value = 'MSD Classic';
    }
  }

  if (selectTexture.value == 'Тканевая') {
    selectProducer.querySelectorAll('option').forEach((el) => {
      el.classList.add('d-none');
    });

    selectProducer
      .querySelector('[data-type=descor')
      .classList.remove('d-none');
    selectProducer.value = 'Descor';
  }

  const eventInput = new Event('input');
  const eventChange = new Event('change');

  document.querySelector('.calc').dispatchEvent(eventInput);
  document.querySelector('.calc').dispatchEvent(eventChange);
}

class Calc {
  constructor(el) {
    this.formEl = el;
    this.textureEl = el.querySelector('[data-texture]');
    this.producerEl = el.querySelector('[data-producer]');
    this.areaEl = el.querySelector('[data-area]');
    this.cornersEl = el.querySelector('[data-corners]');
    this.spotlightsEl = el.querySelector('[data-spotlights]');
    this.chandeliersEl = el.querySelector('[data-chandeliers]');
    this.pipesEl = el.querySelector('[data-pipes]');
    this.resultEl = el.querySelector('[data-calcresult]');
    this.textureAndProducerTax = 0;
    this.cornerTax = 200;
    this.spotlightTax = 500;
    this.chandelierTax = 600;
    this.pipeTax = 300;
    this.area = this.areaEl.valueAsNumber;
    this.textureAndProducerPrice = null;
    this.cornersPrice = null;
    this.spotlightsPrice = null;
    this.chandeliersPrice = null;
    this.pipesPrice = null;
    this.result = null;
    this.formatter = new Intl.NumberFormat('ru');
    this.start();
  }

  makeCalc() {
    this.area = this.areaEl.value;
    this.makeTextureAndProducerTax();
    this.textureAndProducerPrice = this.textureAndProducerTax * this.area;
    this.cornersPrice = this.getCornersPrice();
    this.spotlightsPrice =
      this.spotlightTax * (this.spotlightsEl.valueAsNumber || 0);
    this.chandeliersPrice =
      this.chandelierTax * (this.chandeliersEl.valueAsNumber || 0);
    this.pipesPrice = this.pipeTax * (this.pipesEl.valueAsNumber || 0);

    this.result =
      this.textureAndProducerPrice +
      this.cornersPrice +
      this.spotlightsPrice +
      this.chandeliersPrice +
      this.pipesPrice;
  }

  getCornersPrice() {
    if (this.cornersEl.valueAsNumber >= 5) {
      return this.cornerTax * (this.cornersEl.valueAsNumber - 4);
    } else {
      return 0;
    }
  }

  formChangeHandler() {
    this.makeCalc();

    this.resultEl.textContent = this.formatter.format(this.result);
    this.logResult();
  }

  makeTextureAndProducerTax() {
    if (
      this.textureEl.value == 'Матовая' &&
      this.producerEl.value == 'MSD Classic'
    ) {
      this.textureAndProducerTax = 750;
    } else if (
      this.textureEl.value == 'Матовая' &&
      this.producerEl.value == 'MSD Premium'
    ) {
      this.textureAndProducerTax = 825;
    } else if (
      this.textureEl.value == 'Матовая' &&
      this.producerEl.value == 'MSD Evolution'
    ) {
      this.textureAndProducerTax = 900;
    } else if (
      this.textureEl.value == 'Матовая' &&
      this.producerEl.value == 'BAUF'
    ) {
      this.textureAndProducerTax = 900;
    } else if (
      this.textureEl.value == 'Матовая' &&
      this.producerEl.value == 'Descor'
    ) {
      this.alertNoTextureAndProducer();
    }

    if (
      this.textureEl.value == 'Сатиновая' &&
      this.producerEl.value == 'MSD Classic'
    ) {
      this.textureAndProducerTax = 750;
    } else if (
      this.textureEl.value == 'Сатиновая' &&
      this.producerEl.value == 'MSD Premium'
    ) {
      this.textureAndProducerTax = 825;
    } else if (
      this.textureEl.value == 'Сатиновая' &&
      this.producerEl.value == 'MSD Evolution'
    ) {
      this.textureAndProducerTax = 900;
    } else if (
      this.textureEl.value == 'Сатиновая' &&
      this.producerEl.value == 'BAUF'
    ) {
      this.textureAndProducerTax = 900;
    } else if (
      this.textureEl.value == 'Сатиновая' &&
      this.producerEl.value == 'Descor'
    ) {
      this.alertNoTextureAndProducer();
    }

    if (
      this.textureEl.value == 'Глянцевая' &&
      this.producerEl.value == 'MSD Classic'
    ) {
      this.textureAndProducerTax = 825;
    } else if (
      this.textureEl.value == 'Глянцевая' &&
      this.producerEl.value == 'MSD Premium'
    ) {
      this.textureAndProducerTax = 900;
    } else if (
      this.textureEl.value == 'Глянцевая' &&
      this.producerEl.value == 'MSD Evolution'
    ) {
      this.textureAndProducerTax = 975;
    } else if (
      this.textureEl.value == 'Глянцевая' &&
      this.producerEl.value == 'BAUF'
    ) {
      this.textureAndProducerTax = 975;
    } else if (
      this.textureEl.value == 'Глянцевая' &&
      this.producerEl.value == 'Descor'
    ) {
      this.alertNoTextureAndProducer();
    }

    if (
      this.textureEl.value == 'Тканевая' &&
      this.producerEl.value == 'MSD Classic'
    ) {
      this.alertNoTextureAndProducer();
    } else if (
      this.textureEl.value == 'Тканевая' &&
      this.producerEl.value == 'MSD Premium'
    ) {
      this.alertNoTextureAndProducer();
    } else if (
      this.textureEl.value == 'Тканевая' &&
      this.producerEl.value == 'MSD Evolution'
    ) {
      this.alertNoTextureAndProducer();
    } else if (
      this.textureEl.value == 'Тканевая' &&
      this.producerEl.value == 'BAUF'
    ) {
      this.alertNoTextureAndProducer();
    } else if (
      this.textureEl.value == 'Тканевая' &&
      this.producerEl.value == 'Descor'
    ) {
      this.textureAndProducerTax = 2100;
    }
  }

  alertNoTextureAndProducer() {
    //alert('Такого сочетания Фактуры и Производителя нет.');
    console.log('Такого сочетания Фактуры и Производителя нет.');
    this.textureAndProducerTax = null;
    this.textureAndProducerPrice = null;
  }

  logResult() {
    // console.clear();
    console.log(`
      Итого Материал и производитель: ${this.textureAndProducerPrice} руб;
      Итого Площадь: ${this.area};
      Итого Углы: ${this.cornersPrice} руб;
      Итого Точечные светильники: ${this.spotlightsPrice} руб;
      Итого Люстры: ${this.chandeliersPrice} руб;
      Итого Трубы: ${this.pipesPrice} руб;
      Результат: ${this.result} руб;
    `);
  }

  start() {
    this.formEl.addEventListener('input', () => {
      this.formChangeHandler();
    });

    this.formChangeHandler();
  }
}

function startFormTabs() {
  document.querySelector('#form-tabs').addEventListener('change', (e) => {
    //console.log(e.target.value);
    document.querySelectorAll('.form-tabs-content').forEach((el) => {
      el.classList.add('d-none');
    });
    document
      .querySelector(`[data-formtabs=${e.target.value}]`)
      .classList.remove('d-none');
  });
}

function tabsImgsChanger() {
  document
    .querySelectorAll('.form-tabs-content .wrap-img-16-9 img')
    .forEach((imgEl) => {
      imgEl.addEventListener('click', clickImgPreviewHandler);
    });
}

function clickImgPreviewHandler(e) {
  const parent = e.target.closest('.form-tabs-content');

  parent
    .querySelector('.about-ceiling-type__img')
    .classList.remove('img-lightbox-link--is-binded');
  parent.querySelector('.about-ceiling-type__img').src = e.target.src;
  parent.querySelector('.about-ceiling-type__img').dataset.src = e.target.src;

  imgLightbox('img-lightbox-dinamic');
}

$('#stoimost .present a, #skidki a.dotted').hover(function () {
  var top_pos = $(this).offset().top - $('.present-lenta').height() - 20;
  var left_pos =
    $(this).offset().left +
    $(this).width() / 2 -
    $('.present-lenta').width() / 2;
  $('.present-lenta').css({
    position: 'absolute',
    top: top_pos,
    left: left_pos,
  });
  $('.present-lenta').show();
});

function showGift(e) {
  const giftEl = document.querySelector('.present-lenta');
  const giftElHeight = giftEl.getBoundingClientRect().height;
  const giftElWidth = giftEl.getBoundingClientRect().width;
  const top_pos =
    e.currentTarget.getBoundingClientRect().top - giftElHeight - 20;
  const left_pos =
    e.currentTarget.getBoundingClientRect().left +
    e.currentTarget.getBoundingClientRect().width / 2 -
    giftElWidth / 2 +
    16;

  console.log(top_pos, left_pos);
  giftEl.style.top = top_pos + 'px';
  giftEl.style.left = left_pos + 'px';
  giftEl.classList.add('active');
}

function hideGift() {
  document.querySelector('.present-lenta').classList.remove('active');
}
